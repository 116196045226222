import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeMP3 = () => {
    const formats = [
        { name: 'WAV', slug: 'wav' },
        { name: 'M4A', slug: 'm4a' },
        { name: 'AAC', slug: 'aac' },
        { name: 'OGG', slug: 'ogg' },
        { name: 'FLAC', slug: 'flac' }
    ];

    return (
        <BaseAudioTranscriber 
            title="Free MP3 to Text Transcription | Convert MP3 Files | EchoNote"
            description="Convert MP3 to text with our free MP3 transcription tool. Transcribe .mp3 files and mp3 audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your MP3 file (up to 5MB) to get a text transcript in a few minutes."
            format="mp3"
            metaDescription="Free MP3 to text converter and transcription tool. Convert MP3 files to text, transcribe MP3 audio, and transform .mp3 recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="mp3 to text, mp3 transcription, mp3 converter, .mp3 to text, mp3 file transcribe, mp3 audio to text, transcribe mp3, mp3 transcriber, mp3 file converter, mp3 voice to text, mp3 speech to text, mp3 audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/mp3"
            relatedFormats={formats}
        />
    );
};

export default TranscribeMP3; 