import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeM4A = () => {
    const formats = [
        { name: 'MP3', slug: 'mp3' },
        { name: 'WAV', slug: 'wav' },
        { name: 'AAC', slug: 'aac' },
        { name: 'OGG', slug: 'ogg' },
        { name: 'FLAC', slug: 'flac' }
    ];

    return (
        <BaseAudioTranscriber 
            title="Free M4A to Text Transcription | Convert M4A Files | EchoNote"
            description="Convert M4A to text with our free M4A transcription tool. Transcribe .m4a files and m4a audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your M4A file (up to 5MB) to get a text transcript in a few minutes."
            format="m4a"
            metaDescription="Free M4A to text converter and transcription tool. Convert M4A files to text, transcribe M4A audio, and transform .m4a recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="m4a to text, m4a transcription, m4a converter, .m4a to text, m4a file transcribe, m4a audio to text, transcribe m4a, m4a transcriber, m4a file converter, m4a voice to text, m4a speech to text, m4a audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/m4a"
            relatedFormats={formats}
        />
    );
};

export default TranscribeM4A; 