import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeAAC = () => {
    const formats = [
        { name: 'MP3', slug: 'mp3' },
        { name: 'WAV', slug: 'wav' },
        { name: 'M4A', slug: 'm4a' },
        { name: 'OGG', slug: 'ogg' },
        { name: 'FLAC', slug: 'flac' }
    ];

    return (
        <BaseAudioTranscriber 
            title="Free AAC to Text Transcription | Convert AAC Files | EchoNote"
            description="Convert AAC to text with our free AAC transcription tool. Transcribe .aac files and aac audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your AAC file (up to 5MB) to get a text transcript in a few minutes."
            format="aac"
            metaDescription="Free AAC to text converter and transcription tool. Convert AAC files to text, transcribe AAC audio, and transform .aac recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="aac to text, aac transcription, aac converter, .aac to text, aac file transcribe, aac audio to text, transcribe aac, aac transcriber, aac file converter, aac voice to text, aac speech to text, aac audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/aac"
            relatedFormats={formats}
        />
    );
};

export default TranscribeAAC; 