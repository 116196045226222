import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeWAV = () => {
    const formats = [
        { name: 'MP3', slug: 'mp3' },
        { name: 'M4A', slug: 'm4a' },
        { name: 'AAC', slug: 'aac' },
        { name: 'OGG', slug: 'ogg' },
        { name: 'FLAC', slug: 'flac' }
    ];


    return (
        <BaseAudioTranscriber 
            title="Free WAV to Text Transcription | Convert WAV Files | EchoNote"
            description="Convert WAV to text with our free WAV transcription tool. Transcribe .wav files and wav audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your WAV file (up to 5MB) to get a text transcript in a few minutes."
            format="wav"
            metaDescription="Free WAV to text converter and transcription tool. Convert WAV files to text, transcribe WAV audio, and transform .wav recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="wav to text, wav transcription, wav converter, .wav to text, wav file transcribe, wav audio to text, transcribe wav, wav transcriber, wav file converter, wav voice to text, wav speech to text, wav audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/wav"
            relatedFormats={formats}
        />
    );
};

export default TranscribeWAV; 