import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './components/Login/AuthContext';
import App from './App';
import Test from './components/Test/Test';
// import LoginWithToken from './components/Login/LoginWithToken';
// import LandingPage from './components/Main/LandingPage';
// import Pricing from './components/Main/Pricing';
// import Support from './components/Main/Support';
// import TermsAndPrivacy from './components/Main/TermsAndPrivacy';

import './styles/button.css'
import './styles/style.css'
import './styles/form.css'
import TranscribeToText from './components/Tools/TranscribeToText';
import Login from './components/Login/Login';
import Signup from './components/Login/Signup';
import LoginWithToken from './components/Login/LoginWithToken';
import LandingPage from './components/Page/LandingPage';
import Legal from './components/Page/Legal';
import BlogList from './components/Blog/BlogList';
import BlogPost from './components/Blog/BlogPost';
import TranscribeWAV from './components/Tools/TranscribeWAV';
import TranscribeM4A from './components/Tools/TranscribeM4A';
import TranscribeAAC from './components/Tools/TranscribeAAC';
import TranscribeOGG from './components/Tools/TranscribeOGG';
import TranscribeFLAC from './components/Tools/TranscribeFLAC';
import TranscribeMP3 from './components/Tools/TranscribeMP3';


const AppRouter = () => {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    return (
        <Router>
            <Routes>

                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:slug" element={<BlogPost />} />

                <Route
                    path="/"
                    element={!isAuthenticated ? <LandingPage /> : <Navigate to="/app" />}
                />
                <Route
                    path="/app"
                    element={isAuthenticated ? <App /> : <Navigate to="/login" />}
                />
                <Route
                    path="/legal"
                    element={<Legal />}
                />
                <Route
                    path="/login"
                    element={isAuthenticated ? <Navigate to="/app" /> : <Login />}
                />
                <Route
                    path="/signup"
                    element={isAuthenticated ? <Navigate to="/app" /> : <Signup />}
                />
                <Route
                    path="/login_with_token"
                    element={isAuthenticated ? <Navigate to="/app" /> : <LoginWithToken />}
                />
                <Route
                    path="/tools/transcribe-to-text/mp3"
                    element={<TranscribeMP3 />}
                />
                <Route path="/tools/transcribe-to-text/wav" element={<TranscribeWAV />} />
                <Route path="/tools/transcribe-to-text/m4a" element={<TranscribeM4A />} />
                <Route path="/tools/transcribe-to-text/aac" element={<TranscribeAAC />} />
                <Route path="/tools/transcribe-to-text/ogg" element={<TranscribeOGG />} />
                <Route path="/tools/transcribe-to-text/flac" element={<TranscribeFLAC />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
