import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeOGG = () => {
    const formats = [
        { name: 'MP3', slug: 'mp3' },
        { name: 'WAV', slug: 'wav' },
        { name: 'M4A', slug: 'm4a' },
        { name: 'AAC', slug: 'aac' },
        { name: 'FLAC', slug: 'flac' }
    ];

    return (
        <BaseAudioTranscriber 
            title="Free OGG to Text Transcription | Convert OGG Files | EchoNote"
            description="Convert OGG to text with our free OGG transcription tool. Transcribe .ogg files and ogg audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your OGG file (up to 5MB) to get a text transcript in a few minutes."
            format="ogg"
            metaDescription="Free OGG to text converter and transcription tool. Convert OGG files to text, transcribe OGG audio, and transform .ogg recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="ogg to text, ogg transcription, ogg converter, .ogg to text, ogg file transcribe, ogg audio to text, transcribe ogg, ogg transcriber, ogg file converter, ogg voice to text, ogg speech to text, ogg audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/ogg"
            relatedFormats={formats}
        />
    );
};

export default TranscribeOGG; 