import React from 'react';
import BaseAudioTranscriber from './BaseAudioTranscriber';

const TranscribeFLAC = () => {
    const formats = [
        { name: 'MP3', slug: 'mp3' },
        { name: 'WAV', slug: 'wav' },
        { name: 'M4A', slug: 'm4a' },
        { name: 'AAC', slug: 'aac' },
        { name: 'OGG', slug: 'ogg' }
    ];

    return (
        <BaseAudioTranscriber 
            title="Free FLAC to Text Transcription | Convert FLAC Files | EchoNote"
            description="Convert FLAC to text with our free FLAC transcription tool. Transcribe .flac files and flac audio to text instantly with support for multiple languages and high accuracy."
            manualDescription="Click or Drop your FLAC file (up to 5MB) to get a text transcript in a few minutes."
            format="flac"
            metaDescription="Free FLAC to text converter and transcription tool. Convert FLAC files to text, transcribe FLAC audio, and transform .flac recordings to text with high accuracy. Support for 50+ languages."
            metaKeywords="flac to text, flac transcription, flac converter, .flac to text, flac file transcribe, flac audio to text, transcribe flac, flac transcriber, flac file converter, flac voice to text, flac speech to text, flac audio transcription"
            canonicalUrl="https://echonote.xyz/tools/transcribe-to-text/flac"
            relatedFormats={formats}
        />
    );
};

export default TranscribeFLAC; 